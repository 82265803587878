<template>
    <div class="page">
        <el-header height="70px">
            <div class="inner">
                <div class="logo-box">
                    <el-image class="logo hand" :src="logo" @click="goHome"></el-image>
                </div>
                <el-menu router :default-active="activeIndex" mode="horizontal" @select="handleSelect">
                    <template v-for="(v, k) in menus">
                        <el-menu-item v-if="!v.hidden" :key="k" :index="v.path">
                            <i :class="getIcon(v.meta.icon)"
                               style="font-size: 20px !important;margin-right: 0px !important;"></i>
                            {{ v.meta && v.meta.title || '' }}
                        </el-menu-item>
                    </template>
                </el-menu>
                <div class="user-info-box">
            <span class="user-info">
              
              <span class="user-name" @click="setUser">{{ user.nickName }}</span>
            </span>
                    <span class="out-btn" @click="logoOut">
              <i class="el-icon-switch-button"></i>
              ログアウト</span>
                </div>
            </div>
        </el-header>
        <el-row class="context-box">
            <el-row class="context-p">
                <router-view/>
            </el-row>
        </el-row>
        <div class="oo-footer">
            Copyright © 2022 Qoo10. All rights reserved.
        </div>
    </div>
</template>

<script>
    import {removeToken} from "@/libs/auth";
    import logo from "@/assets/img/logo.png";
    import supplier from "@/views/supplier/suppliers/index"

    export default {
        name: "home",
        components: {supplier},
        data() {
            return {
                logo,
                title: '',
                activeIndex: '0',
                menu: [],
                menus: [],
                user: {}
            }
        },
        watch:{
            $route(to,from){
                this.goBack();
            }
        },
        created() {
            this.menus = this.$store.getters.menus;
            this.user = this.$store.getters.user;
        },
        mounted() {
            if (window.history && window.history.pushState) {
                history.pushState(null, null, document.URL);
                window.addEventListener("popstate", this.goBack, false);
            }
            this.goBack();
        },
        methods: {
            goBack(){
                let path = window.location.pathname;
                if (path === '/home') {
                    this.activeIndex = this.$store.getters.menus[0].path;
                    this.$router.push(this.$store.getters.menus[0].path)
                } else {
                    let menuInfo = this.$store.getters.menus.find(item => {
                        return item.path === path
                    });
                    if (menuInfo) {
                        this.activeIndex = menuInfo.path;
                        this.$router.push(menuInfo.path)
                    } else {
                        this.activeIndex = ''
                    }
                }
            },
            getIcon(val) {
                let icons = {
                    people: 'el-icon-shopping-cart-1',
                    system: 'el-icon-s-tools',
                    list: 'el-icon-notebook-2',
                }
                return icons[val];
            },
            handleSelect(key, keyPath) {
                let menuInfo = this.$store.getters.menus.find(item => {
                    return item.path === key
                });
                if (menuInfo) {
                    this.activeIndex = menuInfo.path;
                    localStorage.setItem('subTitle', menuInfo.meta.title)
                }
            },
            goHome() {

            },
            setUser() {
                this.$router.push('/setUser');
            },
            logoOut() {
                this.$confirm('ログアウトは確定ですか ?', '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: 'キャンセル',
                    type: 'warning'
                })
                    .then(() => {
                        removeToken();
                        localStorage.removeItem("router_menuInfo")
                        this.$store.dispatch("LogOut")
                        this.$router.push('/login');
                    })
                    .catch(() => {
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
  .page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #FFFFFF !important;

    .inner {
      display: flex;
      justify-content: center;

      .logo-box {
        background: #f6f6f6;
        height: 70px;
        width: 150px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
          width: 100px;
          height: 56px;
        }
      }

      .user-info-box {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          cursor: pointer;
        }

        .user-info {
          .user-name {
            color: #0878ec;
          }
        }

        .out-btn {
          margin-left: 20px;
          color: rgb(170, 170, 170);
        }
      }
    }

    .context-box {
      height: 50px;
      background: rgba(242, 242, 242, 0.2980392156862745);
      height: calc(100% - 80px);

      .context-p {
        width: 984px;
        height: 100%;
        margin: auto;
      }
    }
  }

  /deep/ .el-menu {
    min-width: 45vw;
    display: flex;
    align-items: center;
  }

  /deep/ .el-menu.el-menu--horizontal {
    border-bottom: 0px;
  }

  /deep/ .el-menu--horizontal > .el-menu-item {
    font-size: 16px;
    font-family: PingFangSC-Regular, "PingFang SC", sans-serif;
    white-space: nowrap;
    text-transform: none;
    font-weight: 600;
    width: auto;
  }

  /deep/ .is-active {
    border-bottom: 0px solid #FFFFFF !important;
    color: #409EFF !important;
    font-weight: 800 !important;
  }

  /deep/ .el-menu-item:hover {
    color: #409EFF !important;
  }

  ul {
    li {
      width: 150px;
    }
  }
  .oo-footer {
    width: 100%;
    position: absolute;
    bottom: 35px;
    text-align: center;
    font-size: 10px;
    color: #c1c0c0;
    word-wrap: break-word;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "kern";
    font-weight: 400;
    font-family: 'ArialMT', 'Arial', sans-serif;
  }

</style>
